import styles from './styles/Article.module.css'

const ARTICLE_ID = 'artykul'
const Article = ({ article, id = ARTICLE_ID, ...rest }) => (
    article
        ? <article id={id} className={styles.article} {...rest}>
            <div dangerouslySetInnerHTML={{ __html: article }} />
          </article>

        : null
)

export default Article
export { ARTICLE_ID }